import { useTranslation } from "react-i18next";

import Button from "./../../components/Form/Button/Button";

const onClose = () => {
  window.opener = null;
  window.open("", "_self");
  window.close();
};

/**
 * Terms and conditions view
 */
const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-2 max-w-prose">
      <h1 className="my-2">Terms of service</h1>
      <h2>Algemene voorwaarden (samengevat)</h2>
      <p>
        Bij het aanmaken van een account in Aletta accepteer je dat alle data en
        informatie van het gebruik van Aletta wordt opgeslagen en mag worden
        gebruikt voor onderzoeksdoeleinden.
      </p>
      <h2>Template EN</h2>
      <ul className="my-2 terms-summary text-left list-none font-sm">
        <li className="leading-normal">
          <a href="#id-1">1. Introduction</a>
        </li>
        <li className="leading-normal">
          <a href="#id-2">2. Acceptable uses</a>
        </li>
        <li className="leading-normal">
          <a href="#id-3">3. Account Termination and Suspension</a>
        </li>
        <li className="leading-normal">
          <a href="#id-4">4. Property Rights</a>
        </li>
        <li className="leading-normal">
          <a href="#id-5">5. Limitation of Liability</a>
        </li>
        <li className="leading-normal">
          <a href="#id-6">6. Privacy Agreements</a>
        </li>
        <li className="leading-normal">
          <a href="#id-7">7. Amendments and Changes to the Agreement</a>
        </li>
        <li className="leading-normal">
          <a href="#id-8">8. Governing Law or Dispute Resolution</a>
        </li>
        <li className="leading-normal">
          <a href="#id-9">9. Company Contact Information</a>
        </li>
      </ul>

      <h2 id="id-1">1. Introduction</h2>
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <h2 id="id-2">2. Acceptable uses</h2>
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <h2 id="id-3">3. Account Termination and Suspension</h2>
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <h2 id="id-4">4. Property Rights</h2>
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <h2 id="id-5">5. Limitation of Liability</h2>
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <h2 id="id-6">6. Privacy Agreements</h2>
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <h2 id="id-7">7. Amendments and Changes to the Agreement</h2>
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <h2 id="id-8">8. Governing Law or Dispute Resolution</h2>
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <h2 id="id-9">9. Company Contact Information</h2>
      <p className="max-w-prose">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <div className="my-3 text-center">
        <Button className="btn-primary" onClick={onClose}>
          {t("Close")}
        </Button>
      </div>
    </div>
  );
};

export default TermsAndConditions;
