import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useUser } from "./../../context/UserContext";

import { createUser, sendCode } from "api/auth.js";

import Input from "./../../components/Form/Input/Input";
import Password from "./../../components/Form/Input/Password";
import Button from "./../../components/Form/Button/Button";

const isValidInput = () => {
  let isValid = true;

  // validation comes here

  return isValid;
};

/**
 * Register view
 */
const Register = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setUserData } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation
    if (!isValidInput()) {
    }

    // create user, send code per email
    try {
      const response = await createUser({
        email,
        firstName,
        lastName,
        password,
      });

      if (response.code === "ERR_BAD_REQUEST") {
        toast.error(t(response.response.data.detail));
        throw new Error(
          "There was an error. Retry or contact the support. " +
            response.message,
        );
      }

      const response2 = await sendCode({ email });

      if (response2.code === "ERR_BAD_REQUEST") {
        toast.error(t(response2.response.data.detail));
        throw new Error(
          "22 There was an error. Retry or contact the support. " +
            response2.message,
        );
      }

      setUserData({ email: email });
      setEmail("");
      setFirstName("");
      setLastName("");
      navigate("/validate-code");
    } catch (error) {
      setErrorMsg(String(error));
    }
  };

  return (
    <>
      <form
        className="card w-80 p-2 my-2 flex flex-col row-gap-2"
        onSubmit={handleSubmit}
      >
        <h2>{t("Sign up")}</h2>
        {errorMsg ? (
          <p className="error">
            <i className="ri-error-warning-fill mr-1"></i>
            {errorMsg}
          </p>
        ) : (
          ""
        )}
        <Input
          id="email"
          className="mt-1"
          label={t("E-mail")}
          type="email"
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          id="firstName"
          className="mt-1"
          label={t("First name")}
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          id="lastName"
          className="mt-1"
          label={t("Last name")}
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Password
          id="password"
          className="mt-1"
          label={t("Password")}
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
        />
        <p className="my-1 font-xs">
          {t("By clicking Sign Up, you agree to the")}{" "}
          <Link className="font-sm" to="/terms-and-conditions" target="_blank">
            {t("Terms of Service and Privacy Policy")}
          </Link>
          .
        </p>
        <Button className="btn-primary mb-3">{t("Sign up")}</Button>
      </form>
      <Link className="font-sm" to="/login">
        {t("I already have an account")}
      </Link>
    </>
  );
};

export default Register;
