import React, { createContext, useContext, useState, useEffect } from "react";
//import TokenChecker from "components/TokenChecker/TokenChecker";

import { parseJwt } from "utils/utils";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Load auth data from local storage on mount
  useEffect(() => {
    const savedAuthData = JSON.parse(localStorage.getItem("authData"));

    if (!savedAuthData) setIsLoading(false);

    /*
    const isValid = savedAuthData
      ? TokenChecker.isValid(savedAuthData.access_token)
      : false;
    */
    const isValid = true;

    const isExpired = savedAuthData
      ? parseJwt(savedAuthData.access_token)?.exp <
        Math.floor(Date.now() / 1000)
      : false;

    if (savedAuthData && isValid && !isExpired) {
      setAuthData(savedAuthData);
      setIsLoading(false);
    }

    if (isExpired) {
      setAuthData(null);
      localStorage.removeItem("authData");
    }
  }, []);

  // Save auth data to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("authData", JSON.stringify(authData));
  }, [authData]);

  const login = (token) => {
    setAuthData({ token });
  };

  const logout = () => {
    setAuthData(null);
    localStorage.removeItem("authData");
  };

  return (
    <AuthContext.Provider
      value={{ authData, setAuthData, login, logout, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
