import axiosInstance from "api/axiosInstance";

/**
 *  GET /route-of-administration
 *
 *  get all routes of administration
 */
export const getROAs = async (token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get("/route-of-administration", { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /route-of-administration/{route_of_administration}
 *
 *  get a ROA by id
 */
export const getROA = async ({ token, ROAId }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/route-of-administration/${ROAId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
