import React, { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";

import "./Combo.scss";

const Combo = ({
  id,
  className = "",
  label,
  options,
  value,
  onChange,
  onNoneFound,
  query = "",
  setQuery = null,
  disabled,
  readOnly,
  required = false,
  ...rest
}) => {
  const [internalQuery, setInternalQuery] = useState(query);

  const filteredItems =
    internalQuery === ""
      ? options
      : options.filter((item) => {
          return item["label"]
            .toLowerCase()
            .startsWith(internalQuery.toLowerCase());
        });

  if (filteredItems.length === 0 && internalQuery !== "" && onNoneFound) {
    onNoneFound(internalQuery);
  }

  const handleChange = (event) => {
    // if an internal handler is available call it with the current value
    // else call the internal handler
    setQuery
      ? setQuery(event.target.value)
      : setInternalQuery(event.target.value);
  };

  /**
   * implementing readonly
   */

  useEffect(() => {
    const comboContainer = document.getElementById(id);

    if (comboContainer) {
      const input = comboContainer.getElementsByTagName("input");
      const button = comboContainer.getElementsByTagName("button");
      console.log("combo", input, button);

      if (readOnly) {
        /**
         * readOnly not implemented (yet) in headlessui's combo
         * workaround:
         * - clone the objects so all the events handlers are removed
         * - make input readonly
         */
        /**
         * this does not work in production because of the minification
         *
        const newInput = input[0].cloneNode(true);

        input[0].parentNode.replaceChild(newInput, input[0]);
        input[0].readOnly = true;

        const newButton = button[0].cloneNode(true);

        button[0].parentNode.replaceChild(newButton, button[0]);
        */
      }
    }
  }, [id, readOnly]);

  /**
   * end of implementing readonly
   */

  return (
    <div id={id} className={`combo-container ${className}`}>
      <label htmlFor={id} className={value && "filled"}>
        {label}
        {required ? " *" : ""}
      </label>
      <Combobox value={value} onChange={onChange} nullable>
        <div className="flex">
          <Combobox.Input
            onChange={handleChange}
            displayValue={(item) => item?.label}
            required={required}
            readOnly={readOnly}
          />
          <Combobox.Button
            className="btn-combo self-center"
            readOnly={readOnly}
          >
            <i className="ri-arrow-down-s-line"></i>
          </Combobox.Button>
          <Combobox.Options>
            {filteredItems &&
              filteredItems.map((item) => (
                <Combobox.Option key={item["value"]} value={item}>
                  {item?.label}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
};

export default Combo;
