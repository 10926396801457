import axiosInstance from "api/axiosInstance";

/**
 *  PUT /review/session/{session_id}
 *
 *  review a session
 */
export const reviewSession = async ({ token, sessionId, gradeId, notes }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		grade_id: gradeId,
		reviewer_notes: notes,
	};

	return await axiosInstance
		.put(`/review/session/${sessionId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /review/session/{session_id}/submit
 *
 *  submit the review for a session
 */
export const submitReviewedSession = async ({ token, sessionId }) => {
	console.log("submitting reviewed session:", token, sessionId);
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = { body: "empty_body" };

	return await axiosInstance
		.put(`/review/submit/${sessionId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /review/treatment_plan/{treatment_plan_id}
 *
 *  review a treatment plan
 */
export const reviewTreatmentPlan = async ({
	token,
	treatmentPlanId,
	gradeId,
	notes,
}) => {
	console.log(treatmentPlanId, gradeId, notes);
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		grade_id: gradeId,
		reviewer_notes: notes,
	};

	return await axiosInstance
		.put(`/review/treatment_plan/${treatmentPlanId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /review/prescription/{prescription_id}
 *
 *  review a prescription
 */
export const reviewPrescription = async ({
	token,
	prescriptionId,
	gradeId,
	notes,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		grade_id: gradeId,
		reviewer_notes: notes,
	};

	return await axiosInstance
		.put(`/review/prescription/${prescriptionId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
