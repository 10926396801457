import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";
import ReviewForm from "components/ReviewForm/ReviewForm";
import PatientWidget from "components/PatientWidget/PatientWidget";
import PrescriptionsTable from "components/PrescriptionsTable/PrescriptionsTable";
import SelectPatientModal from "components/SelectPatientModal/SelectPatientModal";
import PrescriptionModal from "components/PrescriptionModal/PrescriptionModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import { getSession } from "api/sessions";
import {
	createTreatmentPlan,
	getTreatmentPlan,
	deleteTreatmentPlan,
} from "api/treatmentPlans";
import { getPatient } from "api/patients";
import {
	getPrescriptions,
	createPrescription,
	updatePrescription,
	deletePrescription,
} from "api/prescriptions";
import { reviewTreatmentPlan } from "api/reviews";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

import { isDraft } from "utils/utils";

const SingleTreatmentPlan = ({ readOnly = false }) => {
	const { sessionId, treatmentPlanId } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { authData } = useAuth();
	const { userData, canI } = useUser();

	// session
	const [session, setSession] = useState([]);

	// treatment plan
	const [treatmentPlan, setTreatmentPlan] = useState(null);
	const [refreshTP, setRefreshTP] = useState(false);
	const [showDeleteTPModal, setShowDeleteTPModal] = useState(false);

	// patient
	const [patient, setPatient] = useState(null);

	// prescriptions
	const [refreshTable, setRefreshTable] = useState(false);
	const [refreshReviewForm, setRefreshReviewForm] = useState(false);
	const [prescriptions, setPrescriptions] = useState([]);
	const [prescription, setPrescription] = useState(null);
	const [showSelectPatientModal, setShowSelectPatientModal] = useState(false);
	const [showPrescriptionModal, setShowPrescriptionModal] = useState(false);
	const [showDeletePrescriptionModal, setShowDeletePrescriptionModal] =
		useState(false);

	/**
	 * prescriptions table - actions
	 *
	 *  these are the actions allowed depending on the role
	 *  the actions allowed depending on the status of each item
	 *  are defined in PrescriptionsTable
	 */

	const rowActions = [];

	if (canI("read", "Prescription"))
		rowActions.push({
			name: "read",
			icon: "ri-eye-line",
			command: handleView,
		});

	if (canI("update", "Prescription"))
		rowActions.push({
			name: "edit",
			icon: "ri-pencil-line",
			command: handleEdit,
			title: t("Edit prescription"),
		});
	/*
	if (canI("review", "Prescription"))
		rowActions.push({
			name: "review",
			icon: "ri-check-double-line",
			command: handleReview,
			title: t("Review session"),
		});
	*/
	if (canI("delete", "Prescription"))
		rowActions.push({
			name: "delete",
			icon: "ri-delete-bin-line",
			command: handleDelete,
			title: t("Delete prescription"),
		});

	// table - prescriptions action handlers

	function handleView(row) {
		setPrescription(row);
		setShowPrescriptionModal(true);
	}

	function handleEdit(row) {
		setPrescription(row);
		setShowPrescriptionModal(true);
	}
	/*
	function handleReview(id) {
		// navigate(`/session/${sessionId}/treatment/${id}`);
		console.log("review ", id);
	}
	*/
	function handleDelete(row) {
		setPrescription(row);
		setShowDeletePrescriptionModal(true);
	}

	const handleDeleteConfirmed = async () => {
		setShowDeletePrescriptionModal(false);
		const response = await deletePrescription(
			authData.access_token,
			prescription.id,
		);
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("Prescription deleted!"));
			setRefreshTable(true);
		}
	};

	const handleSubmitPatientModal = async (patient) => {
		/* we need to create the treatment plan at this point and redirect to it
		   so if the user starts adding prescriptions the TP id is already available */
		const response = await createTreatmentPlan({
			token: authData.access_token,
			sessionId: sessionId,
			patientId: patient.patient.id,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else if (response.treatment_plan_id) {
			setPatient(patient);
			navigate(`/session/${sessionId}/treatment/${response.treatment_plan_id}`);
		}
	};

	const handleSubmitPrescription = async (props) => {
		console.log("submitting prescription...");
		const response = await createPrescription(props);
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("Prescription saved!"));
			setRefreshTable(true);
		}
	};

	const handleUpdatePrescription = async (props) => {
		console.log("updating prescription...");
		const response = await updatePrescription(props);
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("Prescription updated!"));
			setRefreshTable(true);
			setRefreshReviewForm(true);
		}
	};

	const handleClosePrescription = () => {
		setShowPrescriptionModal(false);
		setPrescription(null);
		setRefreshTable(true);
		setRefreshReviewForm(true);
	};

	// aside - treatment plan action handlers

	async function handleDeleteTP() {
		console.log("deleting treatment plan...");
		if (!treatmentPlanId) return;
		const response = await deleteTreatmentPlan({
			token: authData.access_token,
			treatmentPlanId: treatmentPlanId,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.errort(response.response.data.detail);
			console.log(response);
		} else {
			toast.success(t("Treatment plan deleted!"));
			setShowDeleteTPModal(false);
			navigate(`/session/${sessionId}`);
		}
	}

	async function handleCloseTP() {
		navigate(`/session/${sessionId}`);
	}

	const handleSubmitReviewTPForm = async ({ grade, notes }) => {
		console.log("reviewing TP...");
		const response = await reviewTreatmentPlan({
			token: authData.access_token,
			treatmentPlanId: treatmentPlanId,
			gradeId: grade.value,
			notes: notes,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("Treatment plan review saved!"));
			setRefreshTable(true);
		}
	};

	/* get the session for displaying information on the sidebar */

	useEffect(() => {
		(async () => {
			console.log("getting session..");
			const sessionData = await getSession(authData.access_token, sessionId);
			if (sessionData.code === "ERR_BAD_REQUEST") {
				toast.error(t(sessionData.response.data.detail));
				console.log(sessionData);
			} else {
				setSession(sessionData);
			}
		})();
	}, [authData.access_token, sessionId, t]);

	/**
	 * get the treatment plan
	 *  - for displaying information on the sidebar
	 *  - use the TP id to get the patient and prescriptions
	 */

	useEffect(() => {
		(async () => {
			if (treatmentPlanId) {
				console.log("getting treatment plan..");
				const treatmentPlan = await getTreatmentPlan(
					authData.access_token,
					treatmentPlanId,
				);
				if (treatmentPlan.code === "ERR_BAD_REQUEST") {
					toast.error(t(treatmentPlan.response.data.detail));
					console.log(treatmentPlan);
				} else {
					setTreatmentPlan(treatmentPlan);
					setRefreshTP(false);
					setRefreshReviewForm(false);
				}
			}
		})();
	}, [authData.access_token, treatmentPlanId, refreshTP, refreshReviewForm, t]);

	/* get the patient */

	useEffect(() => {
		(async () => {
			if (treatmentPlan) {
				console.log("getting patient...");
				const patientData = await getPatient(
					authData.access_token,
					treatmentPlan.patient_id,
				);
				if (patientData.code === "ERR_BAD_REQUEST") {
					toast.error(t(patientData.response.data.detail));
					console.log(patientData);
				} else {
					setPatient(patientData);
					setRefreshTable(false);
				}
			}
		})();
	}, [authData.access_token, treatmentPlan, refreshTable, t]);

	/* get the prescriptions */

	useEffect(() => {
		(async () => {
			if (treatmentPlanId) {
				console.log("get prescriptions...");
				const data = await getPrescriptions(
					authData.access_token,
					treatmentPlanId,
				);
				if (data.code === "ERR_BAD_REQUEST") {
					toast.error(t(data.response.data.detail));
					console.log(data);
				} else {
					setPrescriptions(data);
					setRefreshTable(false);
				}
			}
		})();
	}, [authData.access_token, treatmentPlanId, refreshTable, t]);

	return (
		<>
			<div className="single-tp h-full flex items-stretch">
				<div className="view flex-auto">
					<div className="header">
						<h1>
							{patient
								? `${t("Treatment plan")} - ${patient.patient.first_name} ${
										patient.patient.last_name
								  }`
								: t("New treatment plan")}
							<Badge className="font-xxs ml-1" value={session.status} />
						</h1>
						<div className="flex mb-2">
							<div className="description max-w-4xl">
								<p className="message__note">
									<i className="ri-book-open-line"></i>
									{t(
										"You started a new treatment plan. First you need to select one patient, then you can start prescribing. A treatment plan can contain one or more prescriptions.",
									)}
								</p>
							</div>
						</div>
					</div>
					<div className="content">
						<h2>{t("Patient data")}</h2>
						<PatientWidget
							patient={patient}
							className="mb-3"
							hintText={t(
								"First you need to select a patient, and after that you can start prescribing.",
							)}
							onClick={() => setShowSelectPatientModal(true)}
						/>
						<h2>{t("List of prescriptions")}</h2>
						<PrescriptionsTable
							data={prescriptions}
							actions={rowActions}
							newAction={patient ? () => setShowPrescriptionModal(true) : ""}
							newActionLabel={t("Add prescription")}
							showSearch={false}
							readOnly={!isDraft(session)}
						/>
					</div>
				</div>
				<aside className="aside ml-3 flex flex-col">
					{/*<h3 className="mt-1 mb-2">{t("Treatment plan information")}</h3>*/}
					<div className="py-1 border-b border-slate-300">
						<div className="table font-xs">
							<div className="table-row">
								<strong>{t("Session ID")}</strong>
								<div>
									{sessionId}
									<span className="ml-05">
										{session.class_id ? (
											<Badge value={t("Class-Study")} />
										) : (
											<Badge value={t("Self-Study")} />
										)}
									</span>
								</div>
							</div>
							{/*
							<div className="table-row">
								<strong>{t("Date created")}</strong>
								<div>{formatDate(session.created_at)}</div>
							</div>
							<div className="table-row">
								<strong>{t("Date updated")}</strong>
								<div>{formatDate(session.updated_at)}</div>
							</div>
							*/}
							{userData.user_role !== "Student" && (
								<div className="table-row">
									<strong>{t("Author")}</strong>
									<div>{session.creator}</div>
								</div>
							)}
						</div>
					</div>

					{/*<h3 className="mt-1 mb-2">{t("Treatment plan review")}</h3>*/}
					{treatmentPlan && (
						<ReviewForm
							className="py-1 border-b border-slate-300"
							target={treatmentPlan}
							readOnly={
								!(
									userData.user_role === "Teacher" &&
									session.status === "Submitted"
								)
							}
							buttonCaption={t("Review treatment plan")}
							onClose={() => setRefreshTP(true)}
							onSubmit={handleSubmitReviewTPForm}
						/>
					)}
					<div className="actions mt-auto flex">
						<Button
							className={`btn min-w-auto py-0 bg-transparent font-lg text-primary ${
								session.status !== "Draft" && "disabled"
							}`}
							title={t("Delete treatment plan")}
							onClick={() => setShowDeleteTPModal(true)}
						>
							<i className="ri-delete-bin-line"></i>
						</Button>
						<Button className="btn-primary w-full" onClick={handleCloseTP}>
							{t("Close treatment plan")}
						</Button>
					</div>
				</aside>
			</div>

			{showSelectPatientModal && (
				<SelectPatientModal
					onClose={() => setShowSelectPatientModal(false)}
					onSubmit={handleSubmitPatientModal}
				/>
			)}

			{showPrescriptionModal && (
				<PrescriptionModal
					session={session}
					patient={patient}
					prescriptionId={prescription?.id}
					onClose={handleClosePrescription}
					onSubmit={
						prescription ? handleUpdatePrescription : handleSubmitPrescription
					}
				/>
			)}

			{showDeletePrescriptionModal && (
				<ConfirmationModal
					title={t("Delete prescription?")}
					message={`${t(
						"Are you sure you want to delete the prescription with the ID",
					)} ${prescription.id}?`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeletePrescriptionModal(false)}
					onSubmit={handleDeleteConfirmed}
				/>
			)}

			{showDeleteTPModal && (
				<ConfirmationModal
					message={t("Are you sure you want to delete the treatment plan?")}
					yesMessage="Delete"
					onClose={() => setShowDeleteTPModal(false)}
					onSubmit={handleDeleteTP}
				/>
			)}
		</>
	);
};

SingleTreatmentPlan.displayName = "SingleTreatmentPlan";

export default SingleTreatmentPlan;
