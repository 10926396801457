import axiosInstance from "api/axiosInstance";

/**
 *  GET /patient/history
 *
 *  get all patient histories
 */
export const getPatientHistories = async (token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get("/patient/history", { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /patient/history/{patient_id}
 *
 *  create a patient's history
 */
export const createPatientHistory = async ({
	token,
	patientId,
	description,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		description: description,
	};

	return await axiosInstance
		.post(`/patient/history/${patientId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /patient/history/{patient_id}
 *
 *  get a patient's history by patient Id
 */
export const getPatientHistory = async (token, patientId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/patient/history/${patientId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /patient/history/{patient_history_id}
 *
 *  update a patient's history
 */
export const updatePatientHistory = async ({
	token,
	historyId,
	description,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		description: description,
	};

	return await axiosInstance
		.put(`/patient/history/${historyId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /patient/history/{patient_history_id}
 *
 *  delete a patient's history
 */
export const deletePatientHistory = async (token, historyId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	return await axiosInstance
		.delete(`/patient/history/${historyId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
