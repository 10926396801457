import axiosInstance from "api/axiosInstance";

/**
 *  GET /dose-frequency
 *
 *  get all dose frequencies
 */
export const getDoseFreqs = async (token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get("/dose-frequency", { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /dose-frequency/{dose_frequency}
 *
 *  get a dose frequency by id
 */
export const getDoseFreq = async ({ token, doseFreqId }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/dose-frequency/${doseFreqId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
