import "./Textarea.scss";

const Textarea = ({
  id,
  className = "",
  label,
  value,
  rows = 5,
  onChange,
  readOnly,
  ...rest
}) => {
  let classes = ["textarea-container"];

  if (className) classes.push(className);

  return (
    <div className={classes.join(" ")} readOnly={readOnly}>
      <label htmlFor={id} className={value && "filled"}>
        {label}
      </label>
      <textarea
        id={id}
        value={value}
        rows={rows}
        onChange={onChange}
        readOnly={readOnly}
        {...rest}
      />
    </div>
  );
};

export default Textarea;
