import "./Toggle.scss";

const Toggle = ({ id, className, label, value, onChange, ...rest }) => {
  return (
    <div className={`toggle-container ${className}`}>
      <label className="switch">
        <input
          type="checkbox"
          id={id}
          checked={value}
          onChange={onChange}
          {...rest}
        />
        <span className="slider round"></span>
        <span className="label-text">{label}</span>
      </label>
    </div>
  );
};

export default Toggle;
