import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Button from "components/Form/Button/Button";

// import { AuthContext } from "./../../context/AuthContext";

const NewSessionModal = ({ onSubmit, ...props }) => {
	const { t } = useTranslation();

	return (
		<Modal
			title={t("Create new session")}
			isOpen={props.isOpen}
			onClose={props.onClose}
		>
			<form className="row-gap-1">
				<p className="my-1">
					{t(
						"Choose which kind of session you want to create. For Class sessions you need to have the session ID that you received from your teacher.",
					)}
				</p>
				<div className="flex center mt-3">
					<Button
						className="btn-primary"
						onClick={() => onSubmit("new_home_session")}
					>
						{t("New home session")}
					</Button>
					<Button
						className="btn-primary"
						onClick={() => onSubmit("new_class_session")}
					>
						{t("New class session")}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default NewSessionModal;
