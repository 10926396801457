import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Button from "components/Form/Button/Button";

// import { AuthContext } from "./../../context/AuthContext";

const InformationModal = ({
	title = "Confirmation",
	message,
	yesMessage = "OK",
	onSubmit,
	onClose,
	...props
}) => {
	const { t /*, i18n*/ } = useTranslation();

	return (
		<Modal title={title} isOpen={props.isOpen} onClose={onClose} {...props}>
			<form className="row-gap-1">
				<p className="my-1">{message}</p>
				{props.children}
				<div className="flex center mt-3">
					<Button className="btn-primary" onClick={onSubmit}>
						{t(yesMessage)}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default InformationModal;
