import { useTranslation } from "react-i18next";

export default function Settings() {
  const { t } = useTranslation();

  return (
    <>
      <div className="my-sessions view">
        <div className="header">
          <h1>{t("Settings")}</h1>
          <div className="flex mb-2">
            <div className="description max-w-4xl">
              <p className="message__note">
                <i className="ri-book-open-line"></i>
                {t(
                  "In future versions this view will include administrative settings like setting the lagnauge, locales, taking the system offline for maintenence.",
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Settings.displayName = "Settings";
