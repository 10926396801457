import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "remixicon/fonts/remixicon.css";

import { ErrorProvider } from "context/ErrorContext";
import { AuthProvider } from "context/AuthContext";
import { UserProvider } from "context/UserContext";

import ErrorModal from "components/ErrorModal/ErrorModal";

import App from "./App";

import "./i18n";

import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorProvider>
        <AuthProvider>
          <UserProvider>
            <ErrorModal />
            <App />
          </UserProvider>
        </AuthProvider>
      </ErrorProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
