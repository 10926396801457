import axiosInstance from "api/axiosInstance";

/**
 *  GET /dose-unit
 *
 *  get all dose units
 */
export const getDoseUnits = async (token) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get("/dose-unit", { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /dose-unit/{dose_unit}
 *
 *  get a dose unit by id
 */
export const getDoseUnit = async ({ token, doseUnitId }) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/dose-unit/${doseUnitId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
