import axiosInstance from "api/axiosInstance";

/**
 *  POST /auth/token
 *
 *  login and retrieve a token
 */
export const getAuthToken = async (credentials) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  };
  let data = {
    username: credentials.username,
    password: credentials.password,
    grant_type: "password",
  };

  return await axiosInstance
    .post("/auth/token", data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  POST /auth/create/user
 *
 *  create a new user 1/2
 */

export const createUser = async ({ email, firstName, lastName, password }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let data = {
    email: email,
    first_name: firstName,
    last_name: lastName,
    password: password,
  };

  return await axiosInstance
    .post("/auth/create/user", data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  POST /auth/validate/code
 *
 *  create a new user 2/2
 */

export const validateCode = async ({ email, code, password }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let data = {
    email: email,
    code: code,
    password: password,
  };

  return await axiosInstance
    .post("/auth/validate/code", data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  POST /auth/send_email/code
 *
 *  password resetting 1/3: send code to en email
 */
export const sendCode = async ({ email }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let data = {
    email: email,
  };

  return await axiosInstance
    .post("/auth/send_email/code", data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  POST /auth/reset/password/validate/code
 *
 *  password resetting 2/3: validating the code
 */
export const validateResetCode = async ({ email, code }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let data = {
    email: email,
    code: code,
  };

  return await axiosInstance
    .post("/auth/reset/password/validate/code", data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  POST /auth/reset/password
 *
 *  reset a password / password resetting 3/3
 */
export const setPassword = async (token, email, password) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let data = {
    email: email,
    reset_password_token: token,
    new_password: password,
  };

  return await axiosInstance
    .post("/auth/reset/password", data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
