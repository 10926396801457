import axiosInstance from "api/axiosInstance";

/**
 *  GET /user
 *
 *  read the current user
 */
export const getCurrentUser = async (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return await axiosInstance
    .get("/user", { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  PUT /user
 *
 *  update the current user
 */
export const updateCurrentUser = async (token, firstName, lastName) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  let data = {
    first_name: firstName,
    last_name: lastName,
  };
  return await axiosInstance
    .put("/user", data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  DELETE /user
 *
 *  delete the current user
 */
export const deleteCurrentUser = async () => {};

/**
 *  POST /user/reset/password
 *
 *  update the password of the current user
 */
export const updateUserPassword = async (token, newPassword) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  let data = {
    new_password: newPassword,
  };
  return await axiosInstance
    .post("/user/reset/password", data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  GET /user/revoke/token
 *
 *  renew the token
 */
export const renewToken = async (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return await axiosInstance
    .get("/user/revoke/token", { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  GET /user/management
 *
 *  get all users
 */
export const getUsers = async (token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return await axiosInstance
    .get("/user/management", { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 *  PUT /user/management/{user_id}
 *
 *  update a user's role or block the user
 */
export const updateUser = async (token, id, roleId, isBlocked) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  let data = {
    user_role_id: roleId,
    is_blocked: isBlocked,
  };
  return await axiosInstance
    .put(`/user/management/${id}`, data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
