import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // add headers or perform other actions before making a request
    return config;
  },
  (error) => {
    // const { showError } = useError();
    // handle request errors here (e.g., network issues)
    return Promise.reject(error);
  },
);

// Axios response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    /*const { showError } = useError();

    if (error.response) {
      // Handle HTTP error responses (e.g., 4xx, 5xx)
      showError("An error occurred: " + error.response.status);
    } else if (error.request) {
      // Handle network errors (e.g., no internet connection)
      showError("Network error: Unable to connect to the server.");
    } else {
      // Handle other errors (e.g., canceled requests, timeout)
      showError("An unexpected error occurred.");
    }*/

    return Promise.reject(error);
  },
);

export default axiosInstance;
