import axiosInstance from "api/axiosInstance";

/**
 *  GET /prescription/user/{treatment_plan_id}
 *
 *  get all prescriptions from the treatment plan
 */
export const getPrescriptions = async (token, treatmentPlanId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};

	return await axiosInstance
		.get(`/prescription/user/${treatmentPlanId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  POST /prescription/{treatment_plan_id}
 *
 *  create a prescription in the TP with treatment_plan_id
 */
export const createPrescription = async ({
	token,
	treatmentPlanId,
	drugId,
	ROAId,
	doseUnitId,
	doseFreqId,
	dose,
	doseFreqNumber,
	notes,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		drug_id: drugId,
		route_of_administration_id: ROAId,
		dose_unit_id: doseUnitId,
		dose_frequency_id: doseFreqId,
		dose: dose,
		dose_frequency_number: doseFreqNumber,
		notes: notes,
	};

	return await axiosInstance
		.post(`/prescription/${treatmentPlanId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /prescription/{prescription_id}
 *
 *  read a prescription
 */
export const getPrescription = async (token, prescriptionId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	return await axiosInstance
		.get(`/prescription/${prescriptionId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  PUT /prescription/{prescription_id}
 *
 *  update a prescription
 */
export const updatePrescription = async ({
	token,
	prescriptionId,
	drugId,
	ROAId,
	doseUnitId,
	doseFreqId,
	dose,
	doseFreqNumber,
	notes,
}) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	let data = {
		drug_id: drugId,
		route_of_administration_id: ROAId,
		dose_unit_id: doseUnitId,
		dose_frequency_id: doseFreqId,
		dose: dose,
		dose_frequency_number: doseFreqNumber,
		notes: notes,
	};

	return await axiosInstance
		.put(`/prescription/${prescriptionId}`, data, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  DELETE /prescription/{prescription_id}
 *
 *  delete a prescription
 */
export const deletePrescription = async (token, prescriptionId) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
	return await axiosInstance
		.delete(`/prescription/${prescriptionId}`, { headers })
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};
