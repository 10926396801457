import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import "./Modal.scss";

const Backdrop = () => {
  return <div className="backdrop" />;
};

function ModalWindow({ title, className, onClose, children }) {
  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === "Escape" ? onClose() : null);

    document.body.addEventListener("keydown", closeOnEscapeKey);

    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [onClose]);

  return (
    <div className={`modal flex flex-col ${className}`}>
      <div className="modal-titlebar">
        <h2 className="title">{title}</h2>
        <button className="btn-close" onClick={onClose}>
          <i className="ri-close-line"></i>
        </button>
      </div>
      <div className="modal-content h-full">{children}</div>
    </div>
  );
}

const Modal = (props) => {
  const portalElement = document.getElementById("overlays");

  return (
    <>
      {ReactDOM.createPortal(<Backdrop {...props} />, portalElement)}
      {ReactDOM.createPortal(
        <ModalWindow {...props}>{props.children}</ModalWindow>,
        portalElement,
      )}
    </>
  );
};

export default Modal;
