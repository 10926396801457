import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Form/Button/Button";
import Table from "components/Table/Table";
import { getDate, addLeadingZeros } from "utils/utils";

const Actions = ({ row, actions = [] }) => {
  return (
    <div className="actions-wrapper">
      {actions.map(function (action, idx) {
        return (
          <Button
            key={idx}
            className="action"
            onClick={() => action.command(row)}
            title={action.title}
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

const PatientsTable = ({
  data,
  searchLabel,
  searchColumns,
  actions = [],
  newAction,
  newActionLabel,
  initialState,
  onRowClick,
}) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () =>
      [
        {
          Header: t("ID"),
          accessor: "id",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => addLeadingZeros(value, 5),
        },
        {
          Header: t("First name"),
          accessor: "first_name",
        },
        {
          Header: t("Last name"),
          accessor: "last_name",
        },
        {
          Header: t("Date of birth"),
          accessor: "birth_date",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        {
          Header: t("Age"),
          accessor: "age",
          cssClass: "text-center min-cell-width",
        },
        {
          Header: t("Age group"),
          accessor: "label",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => t(value),
        },
        {
          Header: t("Sex"),
          accessor: "gender",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => t(value),
        },
        {
          Header: t("File status"),
          accessor: "is_active",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) =>
            value ? t("active") : t("not active"),
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          cssClass: "text-center min-cell-width pr-2",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  const patientNameFilter = useCallback((rows, columns, query) => {
    return rows.filter((row) => {
      return [row.values["first_name"], row.values["last_name"]]
        .join(" ")
        .toString()
        .toLowerCase()
        .includes(query);
    });
  }, []);

  return (
    <>
      <Table
        className="bg-white"
        columns={columns}
        data={data}
        searchLabel={searchLabel}
        searchColumns={searchColumns}
        searchFilter={patientNameFilter}
        searchGlobal={true}
        newAction={newAction}
        newActionLabel={newActionLabel}
        initialState={initialState}
        onRowClick={onRowClick}
      />
    </>
  );
};

export default PatientsTable;
