import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Form/Button/Button";
import Table from "components/Table/Table";

const Actions = ({ row, actions = [] }) => {
  return (
    <div className="actions-wrapper">
      {actions.map(function (action, idx) {
        return (
          <Button
            key={idx}
            className="action"
            title={action.title}
            onClick={() =>
              action.command({
                id: row.id,
                email: row.email,
                roleId: row.user_role_id,
                isBlocked: row.is_blocked,
              })
            }
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

const UsersTable = ({ data, actions, newAction, newActionLabel }) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () =>
      [
        /*
        {
          Header: t("ID"),
          accessor: "id",
        },
        */
        {
          Header: t("E-mail"),
          accessor: "email",
        },
        {
          Header: t("First name"),
          accessor: "first_name",
        },
        {
          Header: t("Last name"),
          accessor: "last_name",
        },
        {
          Header: t("Role"),
          accessor: "user_role",
          Cell: ({ cell: { value } }) => t(value),
        },
        {
          Header: t("Status"),
          accessor: "is_blocked",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) =>
            value ? t("blocked") : t("not blocked"),
        },
        {
          Header: t("Confirmed account"),
          accessor: "is_confirmed",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) =>
            value ? t("confirmed") : t("not confirmed"),
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          disableSortBy: true,
          cssClass: "text-center min-cell-width pr-2",
          Cell: ({ row: { original } }) =>
            actions && <Actions row={original} actions={actions} />,
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  return (
    <>
      <Table
        columns={columns}
        data={data}
        searchLabel={t("Search by e-mail, first or last name")}
        searchField={["email", "first_name", "last_name"]}
        newAction={newAction}
        newActionLabel={newActionLabel}
      />
    </>
  );
};

export default UsersTable;
