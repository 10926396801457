import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { parse, format, isValid } from "date-fns";

import { useAuth } from "context/AuthContext";

import { getPatientHistory } from "api/patientHistory";

import Modal from "components/Modal/Modal";
import Button from "components/Form/Button/Button";
import Input from "components/Form/Input/Input";
import Select from "components/Form/Select/Select";

// change the format of a date string
const formatDate = (date, patternIn, patternOut) => {
	if (!(date && patternIn && patternOut)) return;
	const dateObj = parse(date, patternIn, new Date());
	if (!isValid(dateObj)) return;
	return format(dateObj, patternOut);
};

const PatientModal = ({ patientData, onSubmit, ...props }) => {
	const { authData } = useAuth();
	const { t } = useTranslation();
	const [patient, setPatient] = useState(
		patientData || {
			first_name: "",
			last_name: "",
			birth_date: "",
			gender: "",
		},
	);
	const [history, setHistory] = useState("");
	const [birthDate, setBirthDate] = useState(
		patientData
			? formatDate(patient?.birth_date, "yyyy-MM-dd", "dd-MM-yyyy")
			: "",
	);
	const selectSexOptions = [
		{ value: "Female", label: t("Female") },
		{ value: "Male", label: t("Male") },
	];
	const [sexClass, setSexClass] = useState("");

	const formatHistoryData = (data) => {
		const formatted = [];

		// here is the bug!!

		if (data)
			data.forEach((item, idx) => {
				formatted.push(item?.description);
			});

		return formatted.join(", ");
	};

	const validInputs = () => {
		let result = true;

		if (!isValid(parse(birthDate, "dd-MM-yyyy", new Date()))) {
			result = false;
			toast.error("Invalid date. The exepcted format is DD-MM-YYYY.");
		}

		if (patient.gender === "") {
			result = false;
			setSexClass("invalid");
			toast.error("Please specify the gender.");
		}

		return result;
	};

	const validateBirthDate = (target) => {
		if (target.value === "") return;
		if (!isValid(parse(target.value, "dd-MM-yyyy", new Date())))
			target.classList.add("invalid");
		else target.classList.remove("invalid");
	};

	const validateGender = (target) => {
		if (target.value === "") return;
		if (!isValid(parse(target.value, "dd-MM-yyyy", new Date())))
			target.classList.add("invalid");
		else target.classList.remove("invalid");
	};

	/* if the patient is set (editing), get the patient's history */
	useEffect(() => {
		if (patientData)
			(async () => {
				const historyData = await getPatientHistory(
					authData.access_token,
					patientData.id,
				);
				if (historyData === "ERR_BAD_REQUEST") {
					toast.error(t(historyData.response.response.data));
				} else {
					setHistory(formatHistoryData(historyData));
				}
			})();
	}, [authData.access_token, patientData, t]);

	const handleClose = () => {
		setPatient(null);
		props.onClose();
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (validInputs()) {
			onSubmit({
				...patient,
				patient_history: history.split(","),
				birth_date: formatDate(birthDate, "dd-MM-yyyy", "yyyy-MM-dd"),
			});
			setPatient(null);
			setHistory("");
			setBirthDate("");
			props.onClose();
		}
	};

	return (
		<Modal
			title={patient ? t("Edit patient") : t("Create new patient")}
			isOpen={props.isOpen}
			onClose={handleClose}
		>
			<form onSubmit={handleSubmit}>
				<p className="message__note my-1">
					<i className="ri-book-open-line"></i>
					{patient
						? t(
								"You can edit a patient file as long as the file hasn't been used in a treatment plan.",
						  )
						: t(
								"You are going to add a patient. After submitting changes are possible until the patient has been used in a session.",
						  )}
				</p>
				<div className="flex flex-col row-gap-2 mt-3">
					<Input
						id="first-name"
						label={t("First name")}
						value={patient.first_name}
						autoFocus={true}
						required
						onChange={(e) =>
							setPatient({ ...patient, first_name: e.target.value })
						}
					/>
					<Input
						id="last-name"
						label={t("Last name")}
						value={patient.last_name}
						required
						onChange={(e) =>
							setPatient({ ...patient, last_name: e.target.value })
						}
					/>
					<Input
						id="birth-date"
						label={t("Date of birth")}
						value={birthDate}
						// pattern="[0-9]{2}-[0-9]{2}-(19|20)\d{2}"
						// maxLength="10"
						// minLength="10"
						placeholder={t("DD-MM-YYYY")}
						required
						onChange={(e) => setBirthDate(e.target.value)}
						onBlur={(e) => validateBirthDate(e.target)}
						onFocus={(e) => {
							e.target.classList.remove("invalid");
						}}
					/>
					<Select
						id="sex"
						className={sexClass}
						label={t("Sex")}
						value={selectSexOptions.find((obj) => obj.value === patient.gender)}
						options={selectSexOptions}
						allowNone={false}
						required
						onChange={(value) => {
							setPatient({ ...patient, gender: value.value });
						}}
						onFocus={() => {
							setSexClass("");
						}}
					/>
					<Input
						id="history"
						label={t("Medical history (comma separated)")}
						value={history}
						onChange={(e) => setHistory(e.target.value)}
					/>
					<div className="flex center mt-3">
						<Button className="btn-primary">
							{patient ? t("Save patient") : t("Create patient")}
						</Button>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default PatientModal;
