import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { parseJwt } from "utils/utils";

const PrivateRoutes = ({ authData, role }) => {
  const isExpired = authData
    ? parseJwt(authData.access_token).exp < Math.floor(Date.now() / 1000)
    : false;

  return authData && authData.access_token && !isExpired ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
