import "./Input.scss";

const Input = ({
  id,
  className = "",
  label,
  value,
  adornment,
  onChange,
  ...rest
}) => {
  let classes = ["input-container"];

  if (className) classes.push(className);

  return (
    <div className={classes.join(" ")}>
      <label htmlFor={id} className={value && "filled"}>
        {label}
        {rest.required && !rest.readOnly ? " *" : ""}
      </label>
      <input id={id} value={value} onChange={onChange} {...rest} />
      {adornment && <div className="adornment">{adornment}</div>}
    </div>
  );
};

export default Input;
