export const roles = {
	admin: "Administrator",
	teacher: "Teacher",
	student: "Student",
};

export const actions = {
	canCreate: "create",
	canRead: "read",
	canReadAll: "readAll",
	canUpdate: "update",
	canDelete: "delete",
	canView: "view",
	canReview: "review",
};

export const components = {
	dashboard: "Dashboard",
	session: "SingleSession",
	treatmentPlan: "SingleTreatmentPlan",
	prescription: "Prescription",
	class: "Class",
	user: "User",
	patient: "Patient",
	drug: "Drug",
	routeOfAdministration: "Route of Administration",
	doseFreq: "Dose frequency",
	doseUnit: "Dose unit",
};

export const views = {
	dashboard: "Dashboard",
	sessions: "Sessions",
	singleSession: "SingleSession",
	singleTreatmentPlan: "SingleTreatmentPlan",
	classes: "Classes",
	users: "Users",
	patients: "Patients",
	settings: "Settings",
};

export const permissions = {
	[roles.admin]: {
		[actions.canCreate]: [components.class, components.patient],
		[actions.canRead]: [
			components.session,
			components.prescription,
			components.class,
			components.user,
			components.patient,
			components.drug,
			components.routeOfAdministration,
			components.doseFreq,
			components.doseUnit,
		],
		[actions.canReadAll]: [
			components.session,
			components.treatmentPlan,
			components.prescription,
		],
		[actions.canUpdate]: [
			components.class,
			components.user,
			components.patient,
		],
		[actions.canDelete]: [
			components.class,
			components.user,
			components.patient,
		],
		[actions.canView]: [
			views.dashboard,
			views.classes,
			views.users,
			views.patients,
			views.sessions,
			views.singleSession,
			views.singleTreatmentPlan,
			views.settings,
		],
	},
	[roles.teacher]: {
		[actions.canCreate]: [
			components.class,
			components.patient,
			components.session,
			components.treatmentPlan,
		],
		[actions.canRead]: [
			components.session,
			components.treatmentPlan,
			components.prescription,
			components.class,
			components.patient,
			components.drug,
			components.routeOfAdministration,
			components.doseFreq,
			components.doseUnit,
		],
		[actions.canUpdate]: [
			components.session,
			components.treatmentPlan,
			components.prescription,
			components.class,
			components.user,
			components.patient,
		],
		[actions.canDelete]: [
			components.class,
			components.user,
			components.patient,
			components.treatmentPlan,
			components.prescription,
		],
		[actions.canReview]: [
			components.drug,
			views.singleSession,
			views.singleTreatmentPlan,
		],
		[actions.canView]: [
			views.dashboard,
			views.classes,
			views.patients,
			views.sessions,
			views.singleTreatmentPlan,
			views.singleSession,
		],
	},
	[roles.student]: {
		[actions.canCreate]: [
			components.session,
			components.treatmentPlan,
			components.prescription,
		],
		[actions.canRead]: [
			components.session,
			components.treatmentPlan,
			components.prescription,
			components.class,
			components.user,
			components.patient,
		],
		[actions.canUpdate]: [
			components.session,
			components.treatmentPlan,
			components.prescription,
			components.user,
		],
		[actions.canDelete]: [
			components.session,
			components.treatmentPlan,
			components.prescription,
			components.user,
		],
		[actions.canView]: [
			views.dashboard,
			views.sessions,
			views.singleSession,
			views.singleTreatmentPlan,
		],
	},
};
